import { Action, createReducer, on } from '@ngrx/store';
import { OrganizationRequestSearchResults } from '../../models/organization/organization-request-search-results-interface';
import { OrganizationRequest } from '../../models/organization/organization-request.interface';
import { ErrorMessage } from '../../models/error-message.interface';
import {
  CancelChangeRequestsSuccess,
  CreateNewChangeRequests,
  CreateNewChangeRequestsError,
  CreateNewChangeRequestsSuccess,
  LoadChangeRequests,
  LoadChangeRequestsError,
  LoadChangeRequestsSuccess,
  ResetErrorMessage,
  ResetSearchResultsChangeRequests,
  SearchChangeRequestsSuccess,
  UpdateOrganizationRequestsError,
  UpdateOrganizationRequestsSuccess,
} from '../actions/change-requests.actions';
import { unescape } from 'cypress/types/lodash';

export const CHANGE_REQUESTS_FEATURE_KEY = 'changeRequests';

export interface ChangeRequestsState {
  changeRequest: Partial<OrganizationRequest> |undefined;
  changeRequestsSearchResults: OrganizationRequestSearchResults[];
  changeRequestLoading?: boolean;
  changeRequestLoaded: boolean;
  changeRequestError?: ErrorMessage;
}

export const initialState: ChangeRequestsState = {
  changeRequest: undefined,
  changeRequestsSearchResults: [],
  changeRequestLoaded: false,
  changeRequestError: undefined,
  changeRequestLoading: false,
};

const changeRequestsReducer = createReducer(
  initialState,
  on(LoadChangeRequestsSuccess, (state, { changeRequest }) => ({
    ...state,
    changeRequest,
    changeRequestLoaded: true,
    changeRequestLoading: false
  })),
  on(LoadChangeRequests, (state) => ({
    ...state,
    changeRequestLoaded: false,
    changeRequestLoading: true
  })),
  on(LoadChangeRequestsError, (state) => ({
    ...state,
    changeRequestLoaded: false,
    changeRequestLoading: false
  })),
  on(CancelChangeRequestsSuccess, state => ({
    ...state,
    changeRequest: undefined,
    changeRequestsSearchResults: [],
  })),
  on(CreateNewChangeRequests, state => ({
    ...state,
    changeRequestLoading: true,
  })),
  on(CreateNewChangeRequestsSuccess, state => ({
    ...state,
    changeRequestLoading: false,
    changeRequestError: undefined
  })),
  on(CreateNewChangeRequestsError, (state, {error}) => ({
    ...state,
    changeRequestLoading: false,
    changeRequestLoaded: true,
    changeRequestError: error
  })),
  on(
    SearchChangeRequestsSuccess,
    (state, { organizationRequestsSearchResults }) => ({
      ...state,
      changeRequestsSearchResults: organizationRequestsSearchResults,
    })
  ),
  on(ResetSearchResultsChangeRequests, state => ({
    ...state,
    changeRequestsSearchResults: [],
  })),
  on(UpdateOrganizationRequestsSuccess, (state, { request }) => ({
    ...state,
    changeRequest: request,
    changeRequestError: undefined,
  })),
  on(UpdateOrganizationRequestsError, (state, { error }) => ({
    ...state,
    changeRequestError: error,
  })),
  on(ResetErrorMessage, state => ({
    ...state,
    changeRequestError: undefined,
  }))
);

export function reducer(
  state: ChangeRequestsState | undefined,
  action: Action
): ChangeRequestsState {
  return changeRequestsReducer(state, action);
}
