export const environment = {
  production: true,
  environmentName: 'dev',
  runReduxDevTools: true,
  turnstilSiteKey: '1x00000000000000000000AA',
  portalUrl: 'https://www.dev-iamconnected.eu/',
  pcsUrl: 'https://www.accept.portbase.com',
  portbaseSupport: 'https://support.portbase.com/',
  portbaseServiceUrl: 'https://www.portbase.com/services/',
  connectorUrl: 'https://iamc-connect.accept.portbase.com/',
  pcsLoginUrl:
    'https://sso.accept.portbase.com/sso/UI/Login?realm=portbase&goto=https://www.accept.portbase.com/portal/start.do&locale=en',
  apiEndpoints: {
    tokenName: 'SsoToken',
    externalLoginPage: 'https://login.dev-iamconnected.eu',
    externalLoginPageWithClientId:
      'https://login.dev-iamconnected.eu?client_id=0oaovfu23wpsvufmQ0h7&response_type=code',
    externalLoginPageReturnUrl:
      '&redirect_uri=https://www.dev-iamconnected.eu/auth/callback',
    authenticationApiUrl: 'https://auth.dev-iamconnected.eu/authn',
    authenticationLogoutEndpoint:
      'https://autn.dev-iamconnected.eu/oauth2/ausloo3mdbfI5t1BM0h7/v1/logout',
    authenticationLogoutReturnUrl:
      '?post_logout_redirect_uri=https://www.dev-iamconnected.eu',
    activationApiUrl: 'https://auth.dev-iamconnected.eu/authn/activate',
    verifyTokenApiUrl: 'https://autn.dev-iamconnected.eu/api/v1/sessions/me',
    resourcesApiUrl: 'https://api.dev-iamconnected.eu',
    fileserverApiUrl: 'https://files.dev-iamconnected.eu'
  },
  rum: {
    id: 'e4fed673-2b54-4c10-840a-4a438fb9faf1',
    version:'1.0.0',
    region:'eu-west-1',
    guestRoleArn: 'arn:aws:iam::571844716410:role/dev-cognito_rum_unauthenticated',
    identityPoolId: 'eu-west-1:600a82a0-02d0-44d1-a1c9-b31de52168ce',
    endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com'
  }
};
